<template>
	<div>
		<Header ref="Header"/>
		<div class="introduction-container">
			<div class="introduction-tips-box flex">
				<div class="introduction-tips-t1 jump"><a href="space">全部场地</a></div>
				<img class="introduction-tips-arrow" src="../../public/images/arrow_3.png" />
				<div class="introduction-tips-t1 jump" @click="toPage(data.type)">{{data.typeName}}</div>
				<img class="introduction-tips-arrow" src="../../public/images/arrow_3.png" />
				<div>【{{data.city}}】{{data.name}}</div>
			</div>
			<div class="introduction-desc-box row">
				<div class="introduction-desc-picbox">
					<el-carousel :interval="4000" height="400px" ref="spaceSwiper" arrow="always">
					    <el-carousel-item v-for="(item,index) in swiper" :key="index">
							<img :src="item.picture" class="introduction-desc-pic"/>
					    </el-carousel-item>
					</el-carousel>
				</div>
				<div :class="showMore?'introduction-desc-list-more':'introduction-desc-list'">
					<div class="introduction-desc-title">【{{data.city}}】{{data.name}}</div>
					<div :class="showMore?'introduction-desc-list-margin-top':''" class="introduction-desc-place flex">
						<img class="introduction-desc-placepic" src="../../public/images/place_01.png" />
						<div class="introduction-desc-placetext">{{data.address}}</div>
					</div>
					<div :class="showMore?'introduction-desc-list-margin-top':''" class="introduction-desc-lable flex">
						<div v-for="(item,index) in data.mark" :key="index">
							<div v-if="index<3" class="introduction-desc-btn">{{item}}</div>
						</div>
					</div>
					<div :class="showMore?'introduction-desc-text-more ':'introduction-desc-text'" class="" v-html="data.description"></div>
					<div class="introduction-desc-text-tips jump" v-if="isMore" @click="showMoreText()">更多</div>
					<div class="introduction-desc-text-tips jump" style="margin-top: 15px;" v-if="!isMore&&showMore" @click="showMoreText()">收起</div>
					<div :class="showMore?'introduction-desc-funbox-more ':'introduction-desc-funbox'" class="flex">
						<div class="introduction-desc-fun flex">
							<img v-if="data.favorites==0" class="introduction-desc-funpic jump" @click="collec(data.id)"
							src="../../public/images/collect_01.png" />
							<div v-if="data.favorites==0" class="introduction-desc-funtext">收藏</div>
							<img v-if="data.favorites==1" class="introduction-desc-funpic jump" @click="cancel(data.id)"
							src="../../public/images/collect_02.png" />
							<div v-if="data.favorites==1" class="introduction-desc-funtext">取消收藏</div>
						</div>
						<div class="introduction-desc-fun flex">
							<img class="introduction-desc-funpic" src="../../public/images/fire_01.png" />
							<div class="introduction-desc-funtext">{{data.viewCount}}</div>
						</div>
					</div>
					<!-- <div class="introduction-desc-bottom"> -->
						<div class="" :class="showMore?'introduction-desc-bigbtn1-more':'introduction-desc-bigbtn1 jump'" @click="show(data.id,data.name)">查看档期</div>
						<div class="" :class="showMore?'introduction-desc-bigbtn2-more':'introduction-desc-bigbtn2 jump'"  v-if="!userInfo.id" @click="showPrice()">查看价格</div>
						<div class="flex" :class="showMore?'introduction-desc-bigbtn2-more':'introduction-desc-bigbtn2'" v-else>
							<div class="flex" style="margin: auto auto;">
								<div style="color: #FD0202;">￥{{data.price}}</div>
								<div style="margin-left: 10px;">{{data.priceUnit}}</div>
							</div>
						</div>
					<!-- </div> -->
				</div>
			</div>
			<div class="introduction-info-box">
				<div class="introduction-title">场地信息</div>
				<div class="introduction-info-line flex">
					<div class="introduction-info-name">类型</div>
					<div class="introduction-info-text">{{data.typeName}}</div>
					<div class="introduction-info-name" style="margin-left: 150px;">面积</div>
					<div class="introduction-info-text">{{data.size}}㎡</div>
					<div class="introduction-info-name" style="margin-left: 150px;">容纳人数</div>
					<div class="introduction-info-text">{{data.capacity}}人</div>
				</div>
				<div class="introduction-info-line flex">
					<div class="introduction-info-name">咨询</div>
					<div class="introduction-info-tel">{{data.tel}}</div>
				</div>
				<div class="introduction-info-line row">
					<div class="introduction-info-name">用途</div>
					<div class="introduction-info-text">{{data.purpose}}</div>
				</div>
				<div class="introduction-info-line row">
					<div class="introduction-info-name">曾办</div>
					<div class="introduction-info-list">
						<div v-for="(item,index) in data.historyCase" :key="index">
							<div class="introduction-info-text">{{item}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="introduction-facility-box">
				<div class="introduction-title">设施介绍</div>
				<div class="introduction-facility-list flex">
					<div v-for="(item,index) in data.facilities" :key="index">
						<div class="introduction-facility-item flex">
							<img class="introduction-facility-check" src="../../public/images/check_01.png"/>
							<div class="introduction-facility-name">{{item}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="introduction-booking-box">
				<div class="introduction-title">预定须知</div>
				<div class="introduction-booking-list" v-html="content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import  Header from '@/components/header.vue';
	import { Notification, MessageBox, Message } from 'element-ui'
	import {
		mapState
	} from 'vuex';
	export default {
		components:{Header},
		data() {
			return {
				id:0,
				data:{},
				swiper: [],
				content:'',
				price:false,
				isMore:false,
				showMore:false,
			}
		},
		computed:{
			...mapState(['userInfo']),
		},
		mounted() {
			this.$refs.Header.setIndex(1);
			// 场地详情
			this.id=this.$route.query.id || 0;
			if(this.id<0){
				alert(传参有误);
				this.$router.go(-1);
			}
			this.$api.getPlaceDetail(this.id).then(res=>{
				console.log(res);
				res.data.detail.mark=res.data.detail.mark.split(',');
				res.data.detail.facilities=res.data.detail.facilities.split(',');
				res.data.detail.historyCase=res.data.detail.historyCase.split('，');
				this.data=res.data.detail;
				this.swiper=res.data.pictures;
				console.log(this.data.description.length,'length');
				if(parseInt(this.data.description.length)>128){
					this.isMore=true;
				}
			})
			// 获取用户须知
			this.$api.getSheetpage(7).then(res=>{
				console.log(res,'预定须知');
				this.content=res.data[0].content;
			});
		},
		methods: {
			// 收藏场地
			collec(placeId){
				this.$api.addFavorites(placeId).then(res=>{
					console.log(res,'收藏接口返回');
					if(res){
						this.data.favorites=1;
					}
				});
			},
			// 取消收藏
			cancel(placeId){
				this.$api.removeFavorites(placeId).then(res=>{
					console.log(res,'取消收藏返回');
					if(res){
						this.data.favorites=0;
					}
				})
			},
			// 导航跳转
			toPage(type){
				this.$router.push({
					path:'space',
					query:{
						type:type,
					},
				})
			},
			//查看档期查看价格
			show(id,name){
				var data={
					placeId:id,
					placeName:name,
				}
				this.$api.addrRecord(data).then(res=>{
					console.log(res,'增加咨询记录')
					if(res){
						MessageBox.confirm('系统已经收到您的查看申请，我们将尽快与您联系', '系统提示', {
						        confirmButtonText: '确定',
						        type: 'success'
						    }
						)
					}
				})
			},
			showPrice(){
				if(!this.userInfo.id){
					this.$refs.Header.showLayer('login');
				}
			},
			showMoreText(){
				this.showMore=this.showMore?false:true;
				this.isMore=this.isMore?false:true;
			}
		},
	}
</script>

<style scoped>
	
</style>
